
html {
	margin: 0;
	font-family: Arial,Calibri,Verdana,Geneva,sans-serif;
}

body {
	margin: auto;
}

html {
    margin: 0;
}
body {
}
body #header {
    width: 640px;
}
body #content {
    width: 536px;
    margin: auto;
}
body.is-iphone * {
    max-width: 640px;
}
body #iphone {
    background: none;
    height: auto;
    height: 308px;
}
body #iphone iframe {
    margin: 0 0 0 52px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}
body .feature-block {
    width: 536px;
    margin: 0;
    border: none;
    padding: 0;
    border-radius: 0;
}

html.is-iphone {
    width: 640px;
}
body.is-iphone {
	background: black url(images/mobile-gradient.jpg) repeat-y 50% 0;
    margin: 0;
    width: 640px;
}
body.is-iphone #kumobius-header {
    display: none;
}

html.is-ipad {
}
body.is-ipad {
    background: black url(images/background-screen-low.jpg) no-repeat 50% -20px;
    -webkit-background-size: 1920px 1200px;
}
body.is-ipad #iphone iframe {
    margin: 23px 92px;
    margin: 0 0 0 52px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

html.is-desktop {
}
body.is-desktop {
	background: black url(images/background-screen-high.jpg) no-repeat 50% -20px;
}
body.is-desktop #iphone iframe {
    margin: 23px 92px;
    margin: 0 0 0 52px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

a {
	text-decoration: none;
}

#kumobius-header {
	height: 25px;
	padding: 10px 0 20px 0;
	text-align: center;
	font-size: 21px;
	text-shadow: black 0px 2px 4px;
	color: gray;
	position: relative;
	background: rgba(0, 0, 0, 0.8);
    margin: auto;
}

#kumobius-header a {
	color: white;
}

#header {
width: 640px;
margin: auto;
position: relative;
}

#content {
	width: 640px;
	margin: auto;
	z-index: 100;
	position: relative;
	
	margin-top: 20px;
}

#logo {
}

h1 {
	font-size: 50pt;
    line-height: 0;
	padding: 0;
	margin: 0;
	color: white;
}

#iphone {
	width: 640px;
	height: 350px;
}

#iphone iframe {
	margin-left: 92px;
	margin-top: 44px;
}

#purchase {
	margin: auto;
	position: relative;
	clear: both;
	margin-top: 10px;
	margin-bottom: 20px;
}

#purchase a {
	float: left;
	margin: 0 62px 20px;
}

#purchase #buy-now {
	width: 318px;
	height: 107px;
	font-size: 40px;
	line-height: 107px;
	font-family: courier;
	background: grey;
	border: 3px solid black;
	border-radius: 10px;
	text-align: center;
	display: block;
	color: white;
	font-weight: bold;

background: #fa7070; /* Old browsers */
background: -moz-linear-gradient(top, #fa7070 0%, #ef6e67 10%, #e16253 25%, #e76453 37%, #ed6351 50%, #e44432 51%, #f2606a 83%, #fc7295 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fa7070), color-stop(10%,#ef6e67), color-stop(25%,#e16253), color-stop(37%,#e76453), color-stop(50%,#ed6351), color-stop(51%,#e44432), color-stop(83%,#f2606a), color-stop(100%,#fc7295)); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(top, #fa7070 0%,#ef6e67 10%,#e16253 25%,#e76453 37%,#ed6351 50%,#e44432 51%,#f2606a 83%,#fc7295 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(top, #fa7070 0%,#ef6e67 10%,#e16253 25%,#e76453 37%,#ed6351 50%,#e44432 51%,#f2606a 83%,#fc7295 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(top, #fa7070 0%,#ef6e67 10%,#e16253 25%,#e76453 37%,#ed6351 50%,#e44432 51%,#f2606a 83%,#fc7295 100%); /* IE10+ */
background: linear-gradient(to bottom, #fa7070 0%,#ef6e67 10%,#e16253 25%,#e76453 37%,#ed6351 50%,#e44432 51%,#f2606a 83%,#fc7295 100%); /* W3C */
}

#app-store-link {
	background: transparent url(images/app_badge_3.png) no-repeat 50% 50%;
	width: 324px;
	height: 113px;
	
    width: 152px;
	height: 45px;
}

#quotes-block {
	display: none;
	clear: both;
}

.feature-block {
	border: 5px solid black;
	float: left;
	margin: 20px 30px;
	position: relative;
	color: white;
	background: #ac57eB;
	background: #ac57eB;
	padding: 0 20px;
	font-size: 15pt;
	
	border-radius: 10px;

background: #ac57eb; /* Old browsers */
background: -moz-linear-gradient(top, #ac57eb 0%, #8400ea 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ac57eb), color-stop(100%,#8400ea)); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(top, #ac57eb 0%,#8400ea 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(top, #ac57eb 0%,#8400ea 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(top, #ac57eb 0%,#8400ea 100%); /* IE10+ */
background: linear-gradient(to bottom, #ac57eb 0%,#8400ea 100%); /* W3C */
    
    background: #492567;
}

#story-block {
	float: right;
	margin-left: 10px;
}

#critical-block {
	float: left;
	margin-right: 10px;
}

#features-block {
	float: right;
	margin-left: 10px;
}

.feature-block h3 {
	margin: 0;
	padding: 0;
	line-height: 50px;
	color: white;
	font-size: 28px;
    text-align: center;
}

#footer {
	text-align: center;
	clear: both;
	padding: 10px 0 10px 0;
	color: #aaa;
}

#footer a {
	color: #aaa;
}






.clear {
	clear: both;
}

#tagline {
	text-align: center;
	font-size: 20pt;
    line-height: 50px;
    margin: 0;
    padding: 0;
	
	font-weight: bold;
    color: white;
	text-shadow: #333 1px 2px 2px;
}

.headline-quote {
    color: white;
    height: 50px;
    text-align: center;

    margin: 10px 0;
}

.headline-quote div {
    width: 536px;
    height: 50px;
}

.quote {
    line-height: 25px;
    font-size: 17pt;
	text-shadow: #000 1px 2px 2px;
    clear: both;
}

#description-block {
    padding: 0 20px;
    width: 496px;
    text-align: center;
    text-shadow: black 1px 2px 2px;
}

.quote-source {
    color: #888;
	color: #ac57eB;
}

#press-block .quote {
    padding: 10px 20px;
}

#press-block .quote a {
    float: right;
}

#screenshots-block {
	float: left;
    width: 536px;
    height: 302px;
    /*
    margin: 20px 0 0 0;
    */
}

#press-block {
    float: left;
    /*
    margin: 20px 0 0 0;
    */
}
    
body.is-iphone #screenshots-block {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}
    
#screenshots-block * {
    width: 536px;
    height: 302px;
}

#shot-1 {
    background: url(images/shots/1.png);
}

#shot-2 {
    background: url(images/shots/2.png);
}

#shot-3 {
    background: url(images/shots/3.png);
}

#shot-4 {
    background: url(images/shots/4.png);
}

#shot-5 {
    background: url(images/shots/5.png);
}

.coming-soon {
    font-size: 23pt;
    text-align: center;
    font-style: italic;
    background: black;
    border-radius: 15px;
    width: 318px;
    height: 106px;
    line-height: 106px;
    border: 3px solid white;
    margin: auto;
    color: white;

}

.link-list {
    text-align: center;
    color: #aaa;
    font-size: 18pt;
    padding: 20px;
    clear: both;
}

.link-list a {
    color: white;
}


.awards-block {
    margin-left: 2px;
}
.award {
    width: 176px;
    height: 107px;
    margin: 0 1px;
    background: grey;
    float: left;
    background: url(images/award-wreath.png) no-repeat 50% 50%;
    text-align: center;
}
  
.award-title {
    line-height: 32px;
    height: 32px;
    margin-top: 24px;
    color: whiteSmoke;
}

.award-subtitle {
    line-height: 32px;
    height: 32px;
    font-size: 10pt;
    color: #ccc;
}

.compressed .award-title {
    margin-top: 16px;
}   

.compressed .award-subtitle {
    line-height: 22px;
}

.extra-compressed .award-title {
    font-size: 16pt;
    margin-top: 12px;
    line-height: 30px;
}

.extra-compressed .award-subtitle {
    line-height: 22px;
    font-size: 10pt;
    margin-top: 30px;
}





