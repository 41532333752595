html {
  margin: 0;
  font-family: Arial, Calibri, Verdana, Geneva, sans-serif;
}

body {
  margin: auto;
}

html {
  margin: 0;
}

body #header {
  width: 640px;
}

body #content {
  width: 536px;
  margin: auto;
}

body.is-iphone * {
  max-width: 640px;
}

body #iphone {
  background: none;
  height: 308px;
}

body #iphone iframe {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin: 0 0 0 52px;
}

body .feature-block {
  border: none;
  border-radius: 0;
  width: 536px;
  margin: 0;
  padding: 0;
}

html.is-iphone {
  width: 640px;
}

body.is-iphone {
  background: #000 url("mobile-gradient.986201d3.jpg") 50% 0 repeat-y;
  width: 640px;
  margin: 0;
}

body.is-iphone #kumobius-header {
  display: none;
}

body.is-ipad {
  -webkit-background-size: 1920px 1200px;
  background: #000 url("background-screen-low.fb9d58b7.jpg") 50% -20px no-repeat;
}

body.is-ipad #iphone iframe {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin: 0 0 0 52px;
}

body.is-desktop {
  background: #000 url("background-screen-high.920e405a.jpg") 50% -20px no-repeat;
}

body.is-desktop #iphone iframe {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin: 0 0 0 52px;
}

a {
  text-decoration: none;
}

#kumobius-header {
  text-align: center;
  text-shadow: 0 2px 4px #000;
  color: gray;
  background: #000c;
  height: 25px;
  margin: auto;
  padding: 10px 0 20px;
  font-size: 21px;
  position: relative;
}

#kumobius-header a {
  color: #fff;
}

#header {
  width: 640px;
  margin: auto;
  position: relative;
}

#content {
  z-index: 100;
  width: 640px;
  margin: 20px auto auto;
  position: relative;
}

h1 {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 50pt;
  line-height: 0;
}

#iphone {
  width: 640px;
  height: 350px;
}

#iphone iframe {
  margin-top: 44px;
  margin-left: 92px;
}

#purchase {
  clear: both;
  margin: 10px auto 20px;
  position: relative;
}

#purchase a {
  float: left;
  margin: 0 62px 20px;
}

#purchase #buy-now {
  text-align: center;
  color: #fff;
  background: -moz-linear-gradient(top, #fa7070 0%, #ef6e67 10%, #e16253 25%, #e76453 37%, #ed6351 50%, #e44432 51%, #f2606a 83%, #fc7295 100%);
  background: -o-linear-gradient(top, #fa7070 0%, #ef6e67 10%, #e16253 25%, #e76453 37%, #ed6351 50%, #e44432 51%, #f2606a 83%, #fc7295 100%);
  background: -ms-linear-gradient(top, #fa7070 0%, #ef6e67 10%, #e16253 25%, #e76453 37%, #ed6351 50%, #e44432 51%, #f2606a 83%, #fc7295 100%);
  background: linear-gradient(#fa7070 0%, #ef6e67 10%, #e16253 25%, #e76453 37%, #ed6351 50%, #e44432 51%, #f2606a 83%, #fc7295 100%);
  border: 3px solid #000;
  border-radius: 10px;
  width: 318px;
  height: 107px;
  font-family: courier;
  font-size: 40px;
  font-weight: bold;
  line-height: 107px;
  display: block;
}

#app-store-link {
  background: url("app_badge_3.ca9f6c3a.png") 50% no-repeat;
  width: 152px;
  height: 45px;
}

#quotes-block {
  clear: both;
  display: none;
}

.feature-block {
  float: left;
  color: #fff;
  background: -moz-linear-gradient(top, #ac57eb 0%, #8400ea 100%);
  background: -o-linear-gradient(top, #ac57eb 0%, #8400ea 100%);
  background: -ms-linear-gradient(top, #ac57eb 0%, #8400ea 100%);
  background: #492567;
  border: 5px solid #000;
  border-radius: 10px;
  margin: 20px 30px;
  padding: 0 20px;
  font-size: 15pt;
  position: relative;
}

#story-block {
  float: right;
  margin-left: 10px;
}

#critical-block {
  float: left;
  margin-right: 10px;
}

#features-block {
  float: right;
  margin-left: 10px;
}

.feature-block h3 {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 28px;
  line-height: 50px;
}

#footer {
  text-align: center;
  clear: both;
  color: #aaa;
  padding: 10px 0;
}

#footer a {
  color: #aaa;
}

.clear {
  clear: both;
}

#tagline {
  text-align: center;
  color: #fff;
  text-shadow: 1px 2px 2px #333;
  margin: 0;
  padding: 0;
  font-size: 20pt;
  font-weight: bold;
  line-height: 50px;
}

.headline-quote {
  color: #fff;
  text-align: center;
  height: 50px;
  margin: 10px 0;
}

.headline-quote div {
  width: 536px;
  height: 50px;
}

.quote {
  text-shadow: 1px 2px 2px #000;
  clear: both;
  font-size: 17pt;
  line-height: 25px;
}

#description-block {
  text-align: center;
  text-shadow: 1px 2px 2px #000;
  width: 496px;
  padding: 0 20px;
}

.quote-source {
  color: #ac57eb;
}

#press-block .quote {
  padding: 10px 20px;
}

#press-block .quote a {
  float: right;
}

#screenshots-block {
  float: left;
  width: 536px;
  height: 302px;
}

#press-block {
  float: left;
}

body.is-iphone #screenshots-block {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

#screenshots-block * {
  width: 536px;
  height: 302px;
}

#shot-1 {
  background: url("1.487d2b0f.png");
}

#shot-2 {
  background: url("2.00fb95d0.png");
}

#shot-3 {
  background: url("3.41b7a68a.png");
}

#shot-4 {
  background: url("4.c6a3e730.png");
}

#shot-5 {
  background: url("5.adbd41e4.png");
}

.coming-soon {
  text-align: center;
  color: #fff;
  background: #000;
  border: 3px solid #fff;
  border-radius: 15px;
  width: 318px;
  height: 106px;
  margin: auto;
  font-size: 23pt;
  font-style: italic;
  line-height: 106px;
}

.link-list {
  text-align: center;
  color: #aaa;
  clear: both;
  padding: 20px;
  font-size: 18pt;
}

.link-list a {
  color: #fff;
}

.awards-block {
  margin-left: 2px;
}

.award {
  float: left;
  text-align: center;
  background: url("award-wreath.dcc7a327.png") 50% no-repeat;
  width: 176px;
  height: 107px;
  margin: 0 1px;
}

.award-title {
  color: #f5f5f5;
  height: 32px;
  margin-top: 24px;
  line-height: 32px;
}

.award-subtitle {
  color: #ccc;
  height: 32px;
  font-size: 10pt;
  line-height: 32px;
}

.compressed .award-title {
  margin-top: 16px;
}

.compressed .award-subtitle {
  line-height: 22px;
}

.extra-compressed .award-title {
  margin-top: 12px;
  font-size: 16pt;
  line-height: 30px;
}

.extra-compressed .award-subtitle {
  margin-top: 30px;
  font-size: 10pt;
  line-height: 22px;
}

/*# sourceMappingURL=index.d3104c2c.css.map */
